import axios from "axios";
import { ILinearParametersWithNetPosition } from "../models/linearEquationParameters";

export const getLinearEquationParameters = async (
    dateTime: string,
    bzn1?: string,
    bzn2?: string
) => {
    const res = await axios.get("/linearEquationParameters/forDay", {
        params: { dateTime, bzn1, bzn2 },
    });
    return res.data as ILinearParametersWithNetPosition;
};
