export interface ILinearEquationParameters {
    dateTime: string;
    cnE_Name: string;
    contingency_Name: string;
    cnE_Direction: string;
    albE_Parameter?: number;
    aldE_Parameter?: number;
    aT_Parameter?: number;
    bE_Parameter?: number;
    cZ_Parameter?: number;
    dE_Parameter?: number;
    fR_Parameter?: number;
    hR_Parameter?: number;
    hU_Parameter?: number;
    nL_Parameter?: number;
    pL_Parameter?: number;
    rO_Parameter?: number;
    sI_Parameter?: number;
    sK_Parameter?: number;
}

export interface IProcessedLinearParameters {
    id: number;
    pre: boolean;
    crit: boolean;
    cne: string;
    params: Record<string, number | undefined>;
}

export interface ILinearParametersWithNetPosition {
    minimalParametersDto: IProcessedLinearParameters[];
    netPositionParameters: Record<string, number | undefined>;
}

export enum EParameterCountries {
    AlegroBelgium = "albe",
    AlegroGermany = "alde",
    Austria = "at",
    Belgium = "be",
    Czechia = "cz",
    Germany = "de",
    France = "fr",
    Croatia = "hr",
    Hungary = "hu",
    Netherlands = "nl",
    Poland = "pl",
    Romania = "ro",
    Slovenia = "si",
    Slovakia = "sk",
}

export const countriesDropdown = [
    { value: EParameterCountries.AlegroBelgium, label: "Alegro Belgium" },
    { value: EParameterCountries.AlegroGermany, label: "Alegro Germany" },
    { value: EParameterCountries.Austria, label: "Austria" },
    { value: EParameterCountries.Belgium, label: "Belgium" },
    { value: EParameterCountries.Czechia, label: "Czechia" },

    { value: EParameterCountries.Germany, label: "Germany" },
    { value: EParameterCountries.France, label: "France" },
    { value: EParameterCountries.Croatia, label: "Croatia" },
    { value: EParameterCountries.Hungary, label: "Hungary" },
    { value: EParameterCountries.Netherlands, label: "Netherlands" },

    { value: EParameterCountries.Poland, label: "Poland" },
    { value: EParameterCountries.Romania, label: "Romania" },
    { value: EParameterCountries.Slovenia, label: "Slovenia" },
    { value: EParameterCountries.Slovakia, label: "Slovakia" },
];

export enum EParameterBorders {
    AT_CZ = "at_cz",
    AT_DE = "at_de",
    AT_HU = "at_hu",
    AT_SI = "at_si",
    BE_DE = "be_de",

    BE_NL = "be_nl",
    CZ_DE = "cz_de",
    CZ_PL = "cz_pl",
    CZ_SK = "cz_sk",
    DE_FR = "de_fr",

    DE_NL = "de_nl",
    DE_PL = "de_pl",
    HR_HU = "hr_hu",
    HR_SI = "hr_si",
    HU_RO = "hu_ro",

    HU_SI = "hu_si",
    HU_SK = "hu_sk",
    PL_SK = "pl_sk",
}

export const bordersDropdown = [
    { value: EParameterBorders.AT_CZ, label: "Austria-Czechia" },
    { value: EParameterBorders.AT_DE, label: "Austria-Germany" },
    { value: EParameterBorders.AT_HU, label: "Austria-Hungary" },
    { value: EParameterBorders.AT_SI, label: "Austria-Slovenia" },
    { value: EParameterBorders.BE_DE, label: "Belgium-Germany" },

    { value: EParameterBorders.BE_NL, label: "Belgium-Netherlands" },
    { value: EParameterBorders.CZ_DE, label: "Czechia-Germany" },
    { value: EParameterBorders.CZ_PL, label: "Czechia-Poland" },
    { value: EParameterBorders.CZ_SK, label: "Czechia-Slovakia" },
    { value: EParameterBorders.DE_FR, label: "Germany-France" },

    { value: EParameterBorders.DE_NL, label: "Germany-Netherlands" },
    { value: EParameterBorders.DE_PL, label: "Germany-Poland" },
    { value: EParameterBorders.HR_HU, label: "Croatia-Hungary" },
    { value: EParameterBorders.HR_SI, label: "Croatia-Slovenia" },
    { value: EParameterBorders.HU_RO, label: "Hungary-Romania" },

    { value: EParameterBorders.HU_SI, label: "Hungary-Slovenia" },
    { value: EParameterBorders.HU_SK, label: "Hungary-Slovakia" },
    { value: EParameterBorders.PL_SK, label: "Poland-Slovakia" },
];
