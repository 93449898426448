import "./Footer.css";

export const Footer = () => {
    return (
        <footer>
            <div>
                <span>
                    <span>Copyright © 2022</span>|<span>Uprise d.o.o </span>|
                    <span>All rights reserved</span>
                </span>
            </div>
        </footer>
    );
};
