import { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

export const AutoLogoutModal = () => {
    const [open, setOpen] = useState(true);

    const closeModal = () => {
        setOpen(false);
        localStorage.setItem("autoLogout", "false");
    };

    return (
        <Dialog
            className="auto-logout-modal"
            visible={open}
            draggable={false}
            onHide={closeModal}
            header="Your session has expired"
            footer={<Button label="OK" onClick={closeModal} />}
        >
            Please log in again!
        </Dialog>
    );
};
