import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { Accordion, AccordionTab } from "primereact/accordion";
import { IFlowBasedData } from "../../models/flowbasedData";

export interface IMoreInfoDialogProps {
    visible: boolean;
    loading: boolean;
    onHide: () => void;
    data?: IFlowBasedData;
}

export const MoreInfoDialog = ({ visible, loading, onHide, data }: IMoreInfoDialogProps) => {
    return (
        <Dialog
            className="more-info-content"
            visible={visible}
            onHide={onHide}
            header="Detailed info"
        >
            {loading ? (
                <ProgressSpinner />
            ) : (
                <>
                    {data ? (
                        <div>
                            <Accordion multiple activeIndex={[]}>
                                <AccordionTab header={`${data.cnE_ElementType} ${data.cnE_Name}`}>
                                    <div>
                                        {data.cnE_TSO && (
                                            <p>
                                                TSO:
                                                <span> {data.cnE_TSO}</span>
                                            </p>
                                        )}
                                        {data.cnE_Direction && (
                                            <p>
                                                Direction:
                                                <span> {data.cnE_Direction}</span>
                                            </p>
                                        )}
                                        {data.cnE_SubstationFrom && (
                                            <p>
                                                Substation From:
                                                <span> {data.cnE_SubstationFrom}</span>
                                            </p>
                                        )}
                                        {data.cnE_SubstationTo && (
                                            <p>
                                                Substation To:
                                                <span> {data.cnE_SubstationTo}</span>
                                            </p>
                                        )}
                                    </div>
                                </AccordionTab>
                                {(data.contingency_Name || data.contingency_ElementType) && (
                                    <AccordionTab
                                        header={`Contingency ${
                                            data.contingency_ElementType ?? ""
                                        } ${data.contingency_Name ?? ""}`}
                                    >
                                        <div>
                                            {data.contingency_BranchName && (
                                                <p>
                                                    Branch name:
                                                    <span> {data.contingency_BranchName}</span>
                                                </p>
                                            )}
                                            {data.contingency_TSO && (
                                                <p>
                                                    TSO:
                                                    <span> {data.contingency_TSO}</span>
                                                </p>
                                            )}
                                            {data.contingency_SubstationFrom && (
                                                <p>
                                                    Substation From:
                                                    <span> {data.contingency_SubstationFrom}</span>
                                                </p>
                                            )}
                                            {data.contingency_SubstationTo && (
                                                <p>
                                                    Substation To:
                                                    <span> {data.contingency_SubstationTo}</span>
                                                </p>
                                            )}
                                        </div>
                                    </AccordionTab>
                                )}
                            </Accordion>
                            <p>
                                Maximum admissable flow:
                                <span> {data.f_MAX} MW</span>
                            </p>
                            <p>
                                Remaining margin:
                                <span> {data.ram} MW</span>
                            </p>
                        </div>
                    ) : (
                        <p>No data was loaded</p>
                    )}
                </>
            )}
        </Dialog>
    );
};
