import { useCallback, useEffect, useState } from "react";
import {
    calculateAdditionalPoints,
    calculatePointsDistance,
    getLinesToConsider,
    getPolygonVertices,
} from "../../helpers/PolygonHelper";
import { Calendar } from "primereact/calendar";
import { ILine, ILineDefinition, IPoint } from "../../models/plotModels";
import { HourPicker } from "../../components/HourPicker/HourPicker";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { MultiSelect } from "primereact/multiselect";
import { VisualizationPlot } from "../../components/VisualizationPlot/VisualizationPlot";
import { getLinearEquationParameters } from "../../api/linearEquationParameters";
import { getBorderExchangesParameters } from "../../api/borderExchangesParameters";
import {
    countriesDropdown,
    bordersDropdown,
    EParameterCountries,
    IProcessedLinearParameters,
    EParameterBorders,
    ILinearParametersWithNetPosition,
} from "../../models/linearEquationParameters";
import { getDisplayDateFormat } from "../../helpers/DateHelper";
import { filterLineByTso } from "../../helpers/flowbasedDataHelper";
import { useDispatch } from "react-redux";
import { showToastMessage } from "../../actions/toastMessageActions";
import "./Home.css";

// Constants used for graph display, extracted here for easier redefinition.
const closestToConsider = 100;
const closestToDraw = 600;
// Limits to how far from center should intersections be searched.
// TODO: Should probably multiply max found x or y with some appropriate constant.
const initialMaxX = 750000;
const initialMaxY = 750000;

export const Home = () => {
    const dispatch = useDispatch();

    // This set of states defines which parameters are used for lines.
    const [selectedHour, setSelectedHour] = useState(12);
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const [selectedDate, setSelectedDate] = useState<Date | Date[] | undefined>(tomorrow);
    const [dropdownOptions, setDropdownOption] = useState<any[]>(); // FIXME: Type is SelectItemOptionsType but primereact doesnt export it...
    const [selectedBzn1, setSelectedBzn1] = useState<string>(EParameterCountries.Austria);
    const [selectedBzn2, setSelectedBzn2] = useState<string>(EParameterCountries.Belgium);
    const [filteredCountries, setFilteredCountries] = useState<string[]>([]);

    // This set of states defines which of the visualizations are shown.
    const [showBorderView, setShowBorderView] = useState(false);
    const [highlightDomain, setHighLightDomain] = useState<boolean>(true);
    const [showCriticalElements, setShowCritialElements] = useState<boolean>(true);
    const [showMonitoredElements, setShowMonitoredElements] = useState<boolean>(false);
    const [showOnlyLimitingElements, setShowOnlyLimitingElements] = useState<boolean>(true);

    const [lines, setLines] = useState<ILine[]>([]);
    // TODO: Add domain as max intersection with x or y * 2. This domain is only used for calculations of intersections and
    //  should not be used for any display information.
    const [linesToConsider, setLinesToConsider] = useState<ILine[]>([]);
    const [allLinesToDraw, setAllLinesToDraw] = useState<ILine[]>([]);
    const [linesToDraw, setLinesToDraw] = useState<ILine[]>([]);

    const [polygonPoints, setPolygonPoints] = useState<IPoint[]>([]);
    const [polygonDomain, setPolygonDomain] = useState<number>(initialMaxX);

    const [data, setData] = useState<ILineDefinition[]>([]);

    const [boundingLines, setBoundingLines] = useState<number[]>([]);

    const [loadedEquationParameters, setLoadedEquationParameters] = useState<
        IProcessedLinearParameters[]
    >([]);

    const [netX, setNetX] = useState<number>();
    const [netY, setNetY] = useState<number>();

    const [loading, setLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [firstLoad, setFirstLoad] = useState(true);

    const fetchEquationParameters = useCallback(async () => {
        // If border view is selected but selected options are not presend in dropdown don't fetch.
        // This happens when showBorderView state is changed but selectedBzn still didn't update.
        if (
            showBorderView &&
            (!bordersDropdown.find(bd => bd.value === selectedBzn1) ||
                !bordersDropdown.find(bd => bd.value === selectedBzn2))
        )
            return;

        // If country view is selected but selected options are not presend in dropdown don't fetch.
        // This happens when showBorderView state is changed but selectedBzn still didn't update.
        if (
            !showBorderView &&
            (!countriesDropdown.find(bd => bd.value === selectedBzn1) ||
                !countriesDropdown.find(bd => bd.value === selectedBzn2))
        )
            return;

        try {
            let date: Date | undefined = Array.isArray(selectedDate)
                ? selectedDate[0]
                : selectedDate;

            if (date) {
                date.setHours(selectedHour);
                date.setMinutes(0);
                date.setSeconds(0);
                date.setMilliseconds(0);

                setLoading(true);
                setLoadingMessage(
                    `Downloading data for: ${getDisplayDateFormat(date)} ${selectedHour
                        .toString()
                        .padStart(2, "0")}h - ${(selectedHour + 1).toString().padStart(2, "0")}h`
                );

                let res: ILinearParametersWithNetPosition;
                if (showBorderView) {
                    res = await getBorderExchangesParameters(
                        date.toISOString() ?? "",
                        selectedBzn1,
                        selectedBzn2
                    );
                } else {
                    res = await getLinearEquationParameters(
                        date.toISOString() ?? "",
                        selectedBzn1,
                        selectedBzn2
                    );
                }

                //setLoadedEquationParameters(res.map(lep => processLinearParameters(lep)));
                setLoadedEquationParameters(res.minimalParametersDto);
                setNetX(res.netPositionParameters[selectedBzn1]);
                setNetY(res.netPositionParameters[selectedBzn2]);
            }
        } catch {
            dispatch(showToastMessage("An error occurred while trying to load data", "error"));
            setLoading(false);
        } finally {
            setFirstLoad(false);
        }
    }, [selectedDate, selectedHour, selectedBzn1, selectedBzn2, dispatch, showBorderView]);

    useEffect(() => {
        fetchEquationParameters();
    }, [fetchEquationParameters]);

    useEffect(() => {
        if (filteredCountries.length > 0) {
            let filteredLines = allLinesToDraw.filter(l => filterLineByTso(l, filteredCountries));
            setLinesToDraw(filteredLines);
        } else {
            setLinesToDraw(allLinesToDraw);
        }
    }, [filteredCountries, allLinesToDraw]);

    useEffect(() => {
        setDropdownOption(showBorderView ? bordersDropdown : countriesDropdown);
        if (showBorderView) {
            let border = bordersDropdown.find(b => b.value === `${selectedBzn1}_${selectedBzn2}`);
            if (!border) {
                // Borders only go one way. This will ensure that the border is found if selected countries before
                // change to border view are either e.g. Croatia-Slovenia or Slovenia-Croatia.
                border = bordersDropdown.find(b => b.value === `${selectedBzn2}_${selectedBzn1}`);
            }

            if (border !== undefined) {
                setSelectedBzn1(border.value);
                setSelectedBzn2(bordersDropdown.find(b => b.value !== border?.value)?.value ?? "");
            } else {
                setSelectedBzn1(EParameterBorders.AT_CZ);
                setSelectedBzn2(EParameterBorders.AT_DE);
            }
        } else {
            const countries = selectedBzn1.split("_");

            if (countries.length === 2) {
                setSelectedBzn1(countries[0]);
                setSelectedBzn2(countries[1]);
            } else {
                setSelectedBzn1(EParameterCountries.Austria);
                setSelectedBzn2(EParameterCountries.Belgium);
            }
        }
    }, [showBorderView]);

    useEffect(() => {
        // Create lines from loadedEquationParameters using selectedBzn1 and selectedBzn2.
        let newData: ILineDefinition[] = [];

        if (selectedBzn1 && selectedBzn2) {
            loadedEquationParameters.forEach(
                (processedLinearParameters: IProcessedLinearParameters) => {
                    newData.push({
                        info: {
                            ...processedLinearParameters,
                        },
                        x: processedLinearParameters.params[selectedBzn1],
                        y: processedLinearParameters.params[selectedBzn2],
                    });
                }
            );

            setData(newData);
        }
    }, [loadedEquationParameters]);

    useEffect(() => {
        let newLines: ILine[] = [];
        setPolygonPoints([]);
        setPolygonDomain(initialMaxX);
        setLoading(true);
        setLoadingMessage("Calculating domain");

        let [linesToConsider, linesToDraw] = getLinesToConsider(
            data,
            closestToConsider,
            closestToDraw
        );

        // Augmented because additional points are calculated because library only draws lines between points
        // and not infinite line.
        let augmentedLinesToDraw: ILine[] = [];
        linesToDraw.forEach((line: ILineDefinition) => {
            let augmentedLine = calculateAdditionalPoints(line, initialMaxX, initialMaxY);
            if (augmentedLine) augmentedLinesToDraw.push(augmentedLine);
        });

        let augmentedLinesToConsider: ILine[] = [];
        linesToConsider.forEach((line: ILineDefinition) => {
            let augmentedLine = calculateAdditionalPoints(line, initialMaxX, initialMaxY);
            if (augmentedLine) augmentedLinesToConsider.push(augmentedLine);
        });

        setAllLinesToDraw(augmentedLinesToDraw);
        setLinesToConsider(augmentedLinesToConsider);

        setLines(newLines);
    }, [data]);

    const calculatePolygonPoints = useCallback(async () => {
        if (linesToConsider.length > 0) {
            let newPolygonPoints: IPoint[] =
                (await getPolygonVertices(linesToConsider.map(l => l.points))) ?? [];
            setPolygonPoints(newPolygonPoints);

            let max = Math.max(...newPolygonPoints.flatMap(p => [Math.abs(p.x), Math.abs(p.y)]));
            setLoadingMessage("");
            setPolygonDomain(max * 2);
        } else {
            console.log("No lines to consider");
            setPolygonPoints([]);
        }

        if (!firstLoad) {
            setLoading(false);
        }
    }, [linesToConsider, setPolygonDomain, firstLoad]);

    useEffect(() => {
        const newBoundingLines: number[] = [];
        if (linesToConsider.length > 0 && polygonPoints.length) {
            polygonPoints.forEach(pp => {
                let linesFound = 0;

                for (let i = 0; i < linesToConsider.length; i++) {
                    const line = linesToConsider[i];

                    if (linesFound === 2) {
                        break;
                    }

                    let first = line.points[0];
                    let last = line.points[line.points.length - 1];

                    // TODO: Perhaps change this to triangle area as determinant: math seems a bit faster
                    // https://web2.0calc.com/questions/do-theese-3-points-lie-on-the-same-lines-4-2-2-2-5-8-7-or-10-4-3-2-8-17-6-8-pleeeease-respond
                    const linePointDist = calculatePointsDistance(first, last);
                    const popDist =
                        calculatePointsDistance(first, pp) + calculatePointsDistance(pp, last);

                    if (
                        Math.abs(popDist - linePointDist) < 1e-9 &&
                        !newBoundingLines.includes(line.info.id)
                    ) {
                        newBoundingLines.push(line.info.id);
                        linesFound++;
                    }
                }
            });
        }
        setBoundingLines(newBoundingLines);
    }, [linesToConsider, polygonPoints, setBoundingLines]);

    useEffect(() => {
        calculatePolygonPoints();
    }, [calculatePolygonPoints]);

    return (
        <div className="home-container">
            <div className="options-header">
                <div>
                    <Calendar value={selectedDate} onChange={e => setSelectedDate(e.value)} />
                </div>
                <div>
                    <HourPicker selectedHour={selectedHour} setSelectedHour={setSelectedHour} />
                </div>
                <div className="bzn-pickers">
                    <span className="p-float-label">
                        <Dropdown
                            id="bzn1"
                            value={selectedBzn1}
                            options={dropdownOptions?.filter(c => c.value !== selectedBzn2)}
                            onChange={e => setSelectedBzn1(e.value)}
                        />
                        <label htmlFor="bzn1">{showBorderView ? "Border 1" : "Country 1"}</label>
                    </span>
                    <span className="p-float-label">
                        <Dropdown
                            id="bzn2"
                            value={selectedBzn2}
                            options={dropdownOptions?.filter(c => c.value !== selectedBzn1)}
                            onChange={e => setSelectedBzn2(e.value)}
                        />
                        <label htmlFor="bzn2">{showBorderView ? "Border 2" : "Country 2"}</label>
                    </span>
                </div>
            </div>

            <section className="visualisation-content">
                <div></div>
                <VisualizationPlot
                    borderView={showBorderView}
                    linesToConsider={linesToConsider}
                    linesToDraw={linesToDraw}
                    domain={polygonDomain}
                    boundingLines={boundingLines}
                    polygonPoints={polygonPoints}
                    highlightDomain={highlightDomain}
                    showCriticalElements={showCriticalElements}
                    showMonitoredElements={showMonitoredElements}
                    xDisplay={dropdownOptions?.find(c => c.value === selectedBzn1)?.label}
                    yDisplay={dropdownOptions?.find(c => c.value === selectedBzn2)?.label}
                    netX={netX}
                    netY={netY}
                    showOnlyLimitingElements={showOnlyLimitingElements}
                    loading={loading}
                    loadingMessage={loadingMessage}
                />
                <div className="visualisation-options-container">
                    <div className="visualisation-options">
                        <div>Visualization options: </div>

                        <div className="view-options">
                            <span className={showBorderView ? "disabled" : ""}>Country view</span>
                            <InputSwitch
                                checked={showBorderView}
                                onChange={e => setShowBorderView(e.value)}
                            />
                            <span className={!showBorderView ? "disabled" : ""}>Border view</span>
                        </div>

                        <div>
                            <InputSwitch
                                checked={highlightDomain}
                                onChange={e => setHighLightDomain(e.value)}
                            />
                            <span>Highlight domain</span>
                        </div>
                        <div>
                            <InputSwitch
                                checked={showCriticalElements}
                                onChange={e => {
                                    setShowCritialElements(e.value);
                                }}
                            />
                            <span>
                                Show critical elements <br />
                                (Max Z2Z PTDF &gt; 5%)
                            </span>
                        </div>
                        <div>
                            <InputSwitch
                                disabled={!showCriticalElements}
                                checked={showOnlyLimitingElements}
                                onChange={e => setShowOnlyLimitingElements(e.value)}
                            />
                            <span>Show only limiting elements</span>
                        </div>
                        <div>
                            <InputSwitch
                                checked={showMonitoredElements}
                                onChange={e => setShowMonitoredElements(e.value)}
                            />
                            <span>Show monitored elements</span>
                        </div>
                        <div>Show elements from:</div>
                        <div className="countries-filter">
                            <span className="p-float-label">
                                <MultiSelect
                                    inputId="countries-filter"
                                    display="chip"
                                    value={filteredCountries}
                                    options={countriesDropdown.slice(2)}
                                    onChange={e => setFilteredCountries(e.value)}
                                />
                                <label htmlFor="countries-filter">{`${
                                    filteredCountries.length === 0
                                        ? "Filter by element owner"
                                        : `Filter: ${filteredCountries.length} selected`
                                }`}</label>
                            </span>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
