import { Route, Routes } from "react-router-dom";
import { Home } from "./containers/Home/Home";
import { Layout } from "./containers/Layout/Layout";
import { Unauthorized } from "./containers/Unauthorized/Unauthorized";
import { configureAxiosClient } from "./api/axiosClient";
import { UsersDashboard } from "./containers/UsersDashboard/UsersDashboard";
import axios from "axios";
import "./App.css";

configureAxiosClient(axios);

// revert back when support comes!

// const parseJwt = (token: string) => {
//     const base64Url = token.split(".")[1];
//     const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
//     const jsonPayload = decodeURIComponent(
//         Buffer.from(base64, "base64")
//             .toString()
//             .split("")
//             .map(c => {
//                 return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
//             })
//             .join("")
//     );
//     return JSON.parse(jsonPayload).exp;
// };

export const App = () => {
    // const dispatch = useDispatch();
    // const user = useSelector((state: AppState) => state.user);
    // const exp = parseJwt(user.token!) as number;

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         if (new Date().getTime() > new Date(exp * 1000).getTime()) {
    //             localStorage.setItem("autoLogout", "true");
    //             dispatch(logout());
    //         }
    //     }, 30000);
    //     return () => clearInterval(intervalId);
    // }, [dispatch, exp]);

    return (
        <Layout>
            <Routes>
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/user-management" element={<UsersDashboard />} />
                <Route path="*" element={<Home />} />
            </Routes>
        </Layout>
    );
};
