import { IPoint, IIntersectLine, ILineDefinition, ILine } from "../models/plotModels";

const precision = 5;

const getIntersections = async (lines: IPoint[][]) => {
    const lineIntersects: IIntersectLine[] = [];
    lines.forEach(line => {
        const lineIntersect: IIntersectLine = {
            line: line,
            intersects: [],
        };
        const x1 = line[0].x;
        const y1 = line[0].y;
        const x2 = line[3].x;
        const y2 = line[3].y;
        for (const line2 of lines) {
            const x3 = line2[0].x;
            const y3 = line2[0].y;
            const x4 = line2[3].x;
            const y4 = line2[3].y;
            if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) continue;
            const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
            if (denominator === 0) continue;
            const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
            const ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;
            if (ua < 0 || ua > 1 || ub < 0 || ub > 1) continue;
            const x = x1 + ua * (x2 - x1);
            const y = y1 + ua * (y2 - y1);

            if (
                !lineIntersect.intersects.find(
                    li =>
                        li.x.toFixed(precision) === x.toFixed(precision) &&
                        li.y.toFixed(precision) === y.toFixed(precision)
                )
            ) {
                lineIntersect.intersects.push({
                    x: x,
                    y: y,
                });
            }
        }

        lineIntersect.intersects.sort((a, b) => a.x - b.x);
        lineIntersects.push(lineIntersect);
    });
    return lineIntersects;
};

const getNextValue = async (intersects: IPoint[], x0: number, run: string) => {
    let result: IPoint | undefined = undefined;
    if (run === "g") {
        for (const intersect of intersects) {
            if (intersect.x > x0) {
                result = intersect;
                break;
            }
        }
    } else {
        intersects = intersects.reverse();
        for (const intersect of intersects) {
            if (intersect.x < x0) {
                result = intersect;
                break;
            }
        }
    }
    return result;
};

const getStartingPoint = async (lines: IPoint[][]) => {
    const xmax = 1000000;
    const ymax = 0;
    let xmin = -1000000;
    const ymin = 0;
    const lineIntersects: IIntersectLine[] = [];
    for (const line of lines) {
        const x1 = line[0].x;
        const y1 = line[0].y;
        const x2 = line[3].x;
        const y2 = line[3].y;
        const lineIntersect: IIntersectLine = {
            line: line,
            intersects: [],
        };
        if ((x1 === x2 && y1 === y2) || (xmax === xmin && ymax === ymin)) continue;
        const denominator = (ymin - ymax) * (x2 - x1) - (xmin - xmax) * (y2 - y1);
        if (denominator === 0) continue;
        const ua = ((xmin - xmax) * (y1 - ymax) - (ymin - ymax) * (x1 - xmax)) / denominator;
        const ub = ((x2 - x1) * (y1 - ymax) - (y2 - y1) * (x1 - xmax)) / denominator;
        if (ua < 0 || ua > 1 || ub < 0 || ub > 1) continue;
        const x = x1 + ua * (x2 - x1);
        const y = y1 + ua * (y2 - y1);
        lineIntersect.intersects.push({
            x: x,
            y: y,
        });
        lineIntersect.intersects.sort((a, b) => a.x - b.x);
        lineIntersects.push(lineIntersect);
    }
    const intersectionVertices = lineIntersects.map(x => x.intersects).flat();
    const xValues: number[] = [];
    intersectionVertices.forEach(p => {
        if (p.x > 0) xValues.push(p.x);
    });
    return { x: Math.min(...xValues), y: 0 };
};

export const getPolygonVertices = async (lines: IPoint[][]) => {
    try {
        const result: IPoint[] = [];
        const intersections = await getIntersections(lines);
        const startingPoint = await getStartingPoint(lines);

        result.push(startingPoint);
        const startingLine = intersections.filter(i =>
            i.line.some(
                p => p.x.toFixed(precision) === startingPoint.x.toFixed(precision) && p.y === 0
            )
        )[0];

        const startingLineIntersects = startingLine.intersects;
        const x0 = 0;
        const y0 = 0;
        const nextPoints: IPoint[] = [
            (await getNextValue(startingLineIntersects, startingPoint.x, "g"))!,
            (await getNextValue(startingLineIntersects, startingPoint.x, "l"))!,
        ];
        nextPoints.forEach(point => {
            const x1 = startingPoint.x;
            const y1 = startingPoint.y;
            const x2 = point.x;
            const y2 = point.y;
            const d = (x0 - x1) * (y2 - y1) - (y0 - y1) * (x2 - x1);
            if (d > 0) result.push({ x: x2, y: y2 });
        });
        for (let i = 0; i < 50; i++) {
            if (new Set(result.map(x => x.x)).size !== result.length) {
                result.pop();
                break;
            }

            const newCrossedLines = intersections.filter(x =>
                x.intersects.some(
                    p =>
                        p.x.toFixed(precision) === result[result.length - 1].x.toFixed(precision) &&
                        p.y.toFixed(precision) === result[result.length - 1].y.toFixed(precision)
                )
            );
            const newLine =
                i === 0
                    ? newCrossedLines.filter(l =>
                          l.line.every(
                              p =>
                                  p.x.toFixed(precision) !==
                                  result[result.length - 2].x.toFixed(precision)
                          )
                      )[0]
                    : newCrossedLines.filter(l =>
                          l.intersects.every(
                              p =>
                                  p.x.toFixed(precision) !==
                                      result[result.length - 2].x.toFixed(precision) &&
                                  p.y.toFixed(precision) !==
                                      result[result.length - 2].y.toFixed(precision)
                          )
                      )[0];

            if (newLine === undefined) {
                // This is suspicious... If new line is undefined later in the function it will cause an exception because
                // its properties are being accessed.
                // But I am not sure this guarantees entire polygon has been found... While testing it seems to work but
                // if polygon detection breaks, reconsider this..
                break;
            }

            const x1 = result[result.length - 1].x;
            const y1 = result[result.length - 1].y;
            const pointIndex = newLine.intersects.findIndex(
                p =>
                    p.x.toFixed(precision) === result[result.length - 1].x.toFixed(precision) &&
                    p.y.toFixed(precision) === result[result.length - 1].y.toFixed(precision)
            );
            let d;
            if (newLine.intersects[pointIndex - 1]) {
                const x2 = newLine.intersects[pointIndex - 1].x;
                const y2 = newLine.intersects[pointIndex - 1].y;
                d = (x0 - x1) * (y2 - y1) - (y0 - y1) * (x2 - x1);
                if (d > 0) result.push({ x: x2, y: y2 });
            }
            if (newLine.intersects[pointIndex + 1]) {
                const x2 = newLine.intersects[pointIndex + 1].x;
                const y2 = newLine.intersects[pointIndex + 1].y;
                d = (x0 - x1) * (y2 - y1) - (y0 - y1) * (x2 - x1);
                if (d > 0) result.push({ x: x2, y: y2 });
            }
            if (i === 0) result.shift();
        }
        return result;
    } catch {}
};

export const calculatePointsDistance = (point1: IPoint, point2: IPoint) => {
    const x = point1.x - point2.x;
    const y = point1.y - point2.y;
    return Math.sqrt(x * x + y * y);
};

export const calculateAdditionalPoints = (
    line: ILineDefinition,
    domainX: number,
    domainY: number
): ILine | undefined => {
    if (line.y && line.x) {
        let slope = (line.y / line.x) * -1;
        let linePoints: IPoint[] = [];

        linePoints.push({ x: line.x, y: 0 }, { x: 0, y: line.y });
        linePoints.sort((f: IPoint, s: IPoint) => f.x - s.x);

        // Because library only draws lines between points, we need to add the first and last points to the line that are outside of the domain
        // That way it will look like line is drawn using all of available space.
        // This will require rework when we want to support lines parallel to x or y axis.

        if (Math.abs(slope) < 1) {
            // Left point
            linePoints.sort((f: IPoint, s: IPoint) => f.x - s.x);

            linePoints = [
                {
                    x: -domainX * 2,
                    y: linePoints[0].y + slope * (-domainX * 2 - linePoints[0].x),
                },
                ...linePoints,
            ];
            // Right point
            linePoints.push({
                x: 2 * domainX,
                y:
                    linePoints[linePoints.length - 1].y +
                    slope * (2 * domainX - linePoints[linePoints.length - 1].x),
            });
        } else {
            linePoints.sort((f: IPoint, s: IPoint) => f.y - s.y);
            // Bottom point
            linePoints = [
                {
                    y: -domainY * 2,
                    x: linePoints[0].x + (-domainY * 2 - linePoints[0].y) / slope,
                },
                ...linePoints,
            ];
            // Top point
            linePoints.push({
                y: 2 * domainY,
                x:
                    linePoints[linePoints.length - 1].x +
                    (2 * domainY - linePoints[linePoints.length - 1].y) / slope,
            });
        }

        return {
            info: line.info,
            points: linePoints.sort((f: IPoint, s: IPoint) => f.y - s.y),
        };
    } else {
        if (!line.x && !line.y) {
            console.log("Both x and y intersection are undefined! Unable to calculate line.");
            return;
        }

        let linePoints: IPoint[] = [];
        // If either x or y insersection is null slope algoritm above does not work (division by zero).
        // In that case it should be enough to just define 4 points that lie on the line "y = line.x" or "x = line.y" (depending on which intersection is defined).
        if (line.y) {
            // We have y intersection but no x intersection. Line is parallel with x.
            // We asume points [(-domainX, line.y), (0, line.y), (1, line.y), (domainX, line.y)]
            linePoints.push(
                ...[
                    { x: -domainX, y: line.y },
                    { x: 0, y: line.y },
                    { x: 1, y: line.y },
                    { x: domainX, y: line.y },
                ]
            );
        } else if (line.x) {
            // We have x intersection but no y intersection. Line is parallel with y.
            // We assume points [(line.x, -domainY), (line.x, 0), (line.x, 1), (line.x, domainY)]
            linePoints.push(
                ...[
                    { x: line.x, y: -domainY },
                    { x: line.x, y: 0 },
                    { x: line.x, y: 1 },
                    { x: line.x, y: domainY },
                ]
            );
        }

        return {
            info: line.info,
            points: linePoints,
        };
    }
};

// Functions for calculating which lines will be considered for minimal polygon and
// which lines will be drawn to screen.
function lineCompareX(f: ILineDefinition, s: ILineDefinition) {
    if (!f.x) return 1;
    if (!s.x) return -1;

    return Math.abs(f.x) - Math.abs(s.x);
}
function lineCompareY(f: ILineDefinition, s: ILineDefinition) {
    if (!f.y) return 1;
    if (!s.y) return -1;

    return Math.abs(f.y) - Math.abs(s.y);
}
function lineAdd(l: ILineDefinition, arr: ILineDefinition[]) {
    if (!arr.includes(l)) {
        arr.push(l);
    }
}

export const getLinesToConsider = (
    lines: ILineDefinition[],
    numOfClosestToConsider: number,
    numOfClosestToDraw: number
): [ILineDefinition[], ILineDefinition[]] => {
    let positiveX = lines.filter(l => {
        return l.x && l.x > 0;
    });
    let positiveY = lines.filter(l => {
        return l.y && l.y > 0;
    });
    let negativeX = lines.filter(l => {
        return l.x && l.x < 0;
    });
    let negativeY = lines.filter(l => {
        return l.y && l.y < 0;
    });

    let linesToConsider: ILineDefinition[] = [];
    let linesToDraw: ILineDefinition[] = [];

    // Getting lines to consider in polygon calculation.
    positiveX
        //.sort(lineCompareX)
        //.slice(0, numOfClosestToConsider)
        .forEach(l => lineAdd(l, linesToConsider));
    negativeX
        //.sort(lineCompareX)
        //.slice(0, numOfClosestToConsider)
        .forEach(l => lineAdd(l, linesToConsider));
    positiveY
        //.sort(lineCompareY)
        //.slice(0, numOfClosestToConsider)
        .forEach(l => lineAdd(l, linesToConsider));
    negativeY
        //.sort(lineCompareY)
        //.slice(0, numOfClosestToConsider)
        .forEach(l => lineAdd(l, linesToConsider));

    // Getting lines to draw.
    positiveX
        .sort(lineCompareX)
        .slice(0, numOfClosestToDraw)
        .forEach(l => lineAdd(l, linesToDraw));
    negativeX
        .sort(lineCompareX)
        .slice(0, numOfClosestToDraw)
        .forEach(l => lineAdd(l, linesToDraw));
    positiveY
        .sort(lineCompareY)
        .slice(0, numOfClosestToDraw)
        .forEach(l => lineAdd(l, linesToDraw));
    negativeY
        .sort(lineCompareY)
        .slice(0, numOfClosestToDraw)
        .forEach(l => lineAdd(l, linesToDraw));

    return [linesToConsider.filter(l => l.info.pre), linesToDraw];
};
