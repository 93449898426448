import { Menubar } from "primereact/menubar";
import { Link } from "react-router-dom";
import { AvatarSelect } from "../../components/AvatarSelect/AvatarSelect";
import "./Header.css";

export const Header = () => {
    const start = (
        <Link className="menu-item" id="main-logo-link" to="/">
            <img src="/images/icons/em_logo.svg" alt="" />
        </Link>
    );

    const end = (
        <div id="header-right">
            <span className="p-input-icon-left"></span>
            <div className="menu-item">
                <AvatarSelect />
            </div>
        </div>
    );

    return (
        <header className="electricity-header">
            <Link className="menu-item" id="main-logo-link" to="/">
                <img src="/images/icons/em_logo.svg" alt="" />
            </Link>
            <span className="title">Flow Based Domain Visualizer</span>
            <Menubar start={start} end={end} />
        </header>
    );
};
