import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest } from "../../actions/authentificationActions";
import { AppState } from "../../store/configureStore";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { RedirectIfLoggedIn } from "./RedirectIfLoggedIn";
import { AutoLogoutModal } from "./AutoLogoutModal";
import "./Login.css";

export const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loginState = useSelector((state: AppState) => state.login);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const disabled = username.length === 0 || password.length === 0 || loginState.currentlySending;

    const handleKeyPress = (e: any) =>
        !disabled && (e.key === "Enter" || e.charCode === 13)
            ? dispatch(loginRequest({ username, password }))
            : null;

    return (
        <div className="login-container">
            <RedirectIfLoggedIn />
            <div className="center-login-container">
                <section className="login-form-container">
                    <div id="login-logo-container">
                        <img
                            src="/images/icons/em_logo.svg"
                            id="login-logo"
                            alt="Eagle main logo"
                        />
                    </div>
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-user"></i>
                        </span>
                        <InputText
                            id="username"
                            placeholder="Username"
                            value={username}
                            onChange={(e: any) => setUsername(e.target.value)}
                            onKeyPress={(e: any) => handleKeyPress(e)}
                        />
                    </div>

                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-key"></i>
                        </span>
                        <Password
                            id="password"
                            type="password"
                            placeholder="Password"
                            toggleMask
                            feedback={false}
                            value={password}
                            onChange={(e: any) => setPassword(e.target.value)}
                            onKeyPress={(e: any) => handleKeyPress(e)}
                        />
                    </div>
                    <Link className="forgot-pass" to="/forgot-password">
                        <i className="pi pi-lock"></i>Forgot password
                    </Link>
                    <p id="login-error-message">{loginState.error}</p>
                    <hr />
                    <Button
                        loading={loginState.currentlySending}
                        disabled={disabled}
                        onClick={() => {
                            dispatch(loginRequest({ username, password }));
                        }}
                        label="Log in"
                    />
                    <label id="sign-up-label">Don't have an account yet?</label>

                    <Button
                        className="p-button-secondary sign-up"
                        label="Sign up"
                        onClick={e => navigate("/register")}
                    />
                    <span className="contact-us">
                        If you have additional questions, contact us here:{" "}
                        <a href="mailto:innovation@uprise.hr">innovation@uprise.hr</a>
                    </span>
                </section>
            </div>
            {localStorage.getItem("autoLogout") &&
                localStorage.getItem("autoLogout") === "true" && <AutoLogoutModal />}
        </div>
    );
};
