import axios, { AxiosResponse } from "axios";
import { INewUser, UserData } from "../actions/authentificationActions";

export const login = async (username: string, password: string) => {
    const res = await axios.post("/users/authenticate", { username, password });
    const user = await handleResponse(res);
    if (user.token) {
        sessionStorage.setItem("user", JSON.stringify(user));
        sessionStorage.setItem("loggedIn", JSON.stringify(true));
    }
    return user;
};

const handleResponse = async (res: AxiosResponse) => {
    const data = res.data;
    if (res.status !== 200) {
        if (res.status === 401) logout();
        if (res.status === 403) return Promise.reject("403");
        const error = (data && data.message) || res.statusText;
        return Promise.reject(error);
    }
    return data || res.status === 200;
};

export const logout = () => {
    sessionStorage.removeItem("user");
    sessionStorage.setItem("loggedIn", JSON.stringify(false));
    localStorage.setItem("language", "de");
};

export const getUsers = async () => {
    const res = await axios.get("/users");
    return res.data as UserData[];
};

export const createUser = async (userDto: INewUser) => {
    return axios.post("/users", userDto);
};

export const register = async (userDto: INewUser) => {
    return axios.post("/users/register", userDto);
};

export const editUser = async (userDto: UserData) => {
    return axios.put("/users/" + userDto.guid, userDto);
};

export const deleteUser = async (guid: string) => {
    return axios.delete("/users/" + guid);
};

export const forgotPassword = async (username: string) => {
    return axios.post("/users/forgotPassword", username);
};

export const checkPasswordResetToken = async (token: string) => {
    return axios.get("/users/resetPassword", { params: { token: token } });
};

export const resetPassword = async (resetPasswordDto: any) => {
    return axios.post("/users/resetPassword", resetPasswordDto);
};
