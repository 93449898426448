import { AxiosStatic } from "axios";
import { UserData } from "../actions/authentificationActions";

const DEFAULT_API_VERSION = 1;

export const configureAxiosClient = (axios: AxiosStatic) => {
    axios.interceptors.request.use(config => {
        const userData = sessionStorage.getItem("user");
        if (userData) {
            const user = JSON.parse(userData) as UserData;
            config.headers!.Authorization = `Bearer ${user.token}`;
        }
        return config;
    });
    axios.interceptors.request.use(config => {
        const apiVersion = config.apiVersion ?? DEFAULT_API_VERSION;
        config.baseURL = config.baseURL ?? `/api/v${apiVersion}/`;
        return config;
    });
    axios.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            console.error(error);
            return Promise.reject(error);
        }
    );
};

declare module "axios" {
    export interface AxiosRequestConfig {
        apiVersion?: number;
    }
}
