import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RedirectIfLoggedIn } from "./RedirectIfLoggedIn";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { INewUser, userInit } from "../../actions/authentificationActions";
import { register } from "../../api/users";
import { Toast } from "primereact/toast";
import { showToastMessage } from "../../actions/toastMessageActions";
import "./Login.css";

export const Register = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [newUser, setNewUser] = useState<INewUser>(userInit);
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [registerErr, setRegisterErr] = useState("");

    const toast = useRef<Toast>(null);

    const createAccount = async () => {
        if (!newUser.userName) {
            setRegisterErr("Email is a required field");
        } else if (!newUser.firstName || !newUser.lastName) {
            setRegisterErr("First and last name are required fields");
        } else if (!newUser.password) {
            setRegisterErr("Password is a required field");
        } else if (newUser.password !== passwordConfirmation) {
            setRegisterErr("Passwords do not match");
        } else {
            try {
                setRegisterErr("");
                await register(newUser);
                toast?.current?.show({
                    severity: "success",
                    summary: "Account created!",
                    detail: "You will be able to login after admin approves your account",
                    sticky: true,
                });
                setNewUser(userInit);
                setPasswordConfirmation("");
            } catch (err: any) {
                setRegisterErr(err.response.data);
                dispatch(showToastMessage("Error while creating account", "error"));
            }
        }
    };

    return (
        <div className="login-container">
            <RedirectIfLoggedIn />
            <Toast ref={toast} />
            <div className="center-login-container register">
                <section className="login-form-container">
                    <div id="login-logo-container">
                        <img
                            src="/images/icons/em_logo.svg"
                            id="login-logo"
                            alt="Eagle main logo"
                        />
                    </div>
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-user"></i>
                        </span>
                        <InputText
                            id="username"
                            placeholder="Email"
                            value={newUser.userName}
                            onChange={e => setNewUser({ ...newUser, userName: e.target.value })}
                        />
                    </div>

                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-user"></i>
                        </span>
                        <InputText
                            id="firstName"
                            placeholder="First Name"
                            value={newUser.firstName}
                            onChange={e => setNewUser({ ...newUser, firstName: e.target.value })}
                        />
                    </div>

                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-user"></i>
                        </span>
                        <InputText
                            id="lastName"
                            placeholder="Last name"
                            value={newUser.lastName}
                            onChange={e => setNewUser({ ...newUser, lastName: e.target.value })}
                        />
                    </div>

                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-key"></i>
                        </span>
                        <Password
                            id="password"
                            type="password"
                            placeholder="Password"
                            toggleMask
                            feedback={false}
                            value={newUser.password}
                            onChange={(e: any) =>
                                setNewUser({ ...newUser, password: e.target.value })
                            }
                        />
                    </div>

                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-key"></i>
                        </span>
                        <Password
                            id="passwordConfirm"
                            type="password"
                            placeholder="Confirm password"
                            toggleMask
                            feedback={false}
                            value={passwordConfirmation}
                            onChange={(e: any) => setPasswordConfirmation(e.target.value)}
                        />
                    </div>

                    <p id="login-error-message">{registerErr}</p>
                    <hr />

                    <Button
                        label="Register"
                        className="p-button-secondary sign-up"
                        onClick={createAccount}
                    />
                    <label id="sign-up-label">You already have an account?</label>
                    <Button
                        label="Log in"
                        className="log-in-button"
                        onClick={e => navigate("/login")}
                    />
                </section>
            </div>
        </div>
    );
};
