import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "primereact/avatar";
import { AppState } from "../../store/configureStore";
import { Dropdown } from "primereact/dropdown";
import { logout, UserRole } from "../../actions/authentificationActions";
import { UserHasRole } from "../../helpers/RolesHelper";
import { Link } from "react-router-dom";
import "./AvatarSelect.css";

export const AvatarSelect = () => {
    const user = useSelector((state: AppState) => state.user);
    const dispatch = useDispatch();

    const logOut = (
        <div className="avatar-select-logout" onClick={() => dispatch(logout())}>
            <span id="logout">Logout</span> <i className="pi pi-sign-out" />
        </div>
    );

    const userManagement = (
        <div className="avatar-select-user-management avatar-dropdown-element">
            <Link className="menu-link" to="/user-management">
                <span id="user-management">Users</span> <i className="pi pi-users" />
            </Link>
        </div>
    );

    let options: (JSX.Element | string)[] = [];
    if (UserHasRole(user, UserRole.Admin)) options = [userManagement, logOut];
    if (UserHasRole(user, UserRole.User)) options = [logOut];

    const onAvatarClick = () => {
        document.getElementById("avatar-select-dropdown")?.click();
    };

    return (
        <>
            <span className="name">Hello, {user.firstName}</span>
            <div id="avatar-select" className="menu-item" onClick={onAvatarClick}>
                <Avatar icon="pi pi-user" shape="circle" />
                <Dropdown id="avatar-select-dropdown" options={options} />
            </div>
        </>
    );
};
