import { Reducer } from "redux";

import {
    SET_USER,
    CLEAR_USER,
    AuthentificationActionType,
    UserData,
    CLEAR_PASSWORD_RECOVERY_TOKEN,
} from "../actions/authentificationActions";

const savedState: UserData | null =
    sessionStorage.getItem("user") !== null
        ? JSON.parse(sessionStorage.getItem("user") || "")
        : null;

const initialState: UserData = {
    guid: undefined,
    userName: undefined,
    firstName: undefined,
    lastName: undefined,
    token: undefined,
    timesLoggedIn: 0,
    roles: undefined,
};

export const userReducer: Reducer<UserData> = (
    state: UserData = savedState || initialState,
    action: AuthentificationActionType
): UserData => {
    switch (action.type) {
        case SET_USER:
            return { ...action.user };
        case CLEAR_USER:
            return { ...initialState };
        case CLEAR_PASSWORD_RECOVERY_TOKEN:
            return { ...state, passwordRecoveryToken: undefined };
        default:
            return state;
    }
};

export default userReducer;
