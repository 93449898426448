import { Button } from "primereact/button";
import { CSSProperties, useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
    Hint,
    HorizontalGridLines,
    LineMarkSeries,
    MarkSeries,
    PolygonSeries,
    VerticalGridLines,
    XAxis,
    XYPlot,
    YAxis,
} from "react-vis";
import { Slider } from "primereact/slider";
import { Message } from "primereact/message";
import { showToastMessage } from "../../actions/toastMessageActions";
import { getFlowBasedData } from "../../api/flowbasedData";
import { IFlowBasedData } from "../../models/flowbasedData";
import { ILine, IPoint } from "../../models/plotModels";
import { Loader } from "../Loader/Loader";
import { MoreInfoDialog } from "./MoreInfoDialog";
import { IWindowSize, useWindowSize } from "../../hooks/ResizeHook";
import "react-vis/dist/style.css";
import "./VisualizationPlot.css";

// Using custom style objects for hint component because Hint does not accept className
const displayStyle: CSSProperties = {
    opacity: 1,
    bottom: "0 !important",
    top: "unset !important",
    right: "0 !important",
    left: "unset !important",
    inset: "unset 0 0 unset !important",
    transition: "opacity 1s",
};
const hideStyle: CSSProperties = {
    ...displayStyle,
    opacity: 0,
    pointerEvents: "none",
};

export interface IVisualizationPlotProps {
    borderView: boolean;
    linesToConsider: ILine[];
    linesToDraw: ILine[];
    polygonPoints: IPoint[];
    boundingLines: number[];
    highlightDomain: boolean;
    showCriticalElements: boolean;
    showMonitoredElements: boolean;
    showOnlyLimitingElements: boolean;
    xDisplay?: string;
    yDisplay?: string;
    netX?: number;
    netY?: number;
    loading: boolean;
    loadingMessage: string;
    domain: number;
}

const displayHintMs: number = 500000;

interface IHintText {
    cneName: string;
    cneElementType: string;
    conName: string;
    conElementType: string;
    isCritical: boolean;
}

// Ratio of how much X axis is wider than Y axis.
// Ratio is important for calcualting displayed domains because if x and y have the same
// domain and different sizes displayed lines and polygon will have skewed sizes..
const YRatio = 6.5 / 10;
const pageWidthBreakpoint = 1700;
const plotWidthLarge = 950;
const plotWidthSmall = 750;

// Default level of zoom. If user sets zoom to lower than this, we need to calculate
const defaultZoom = 5;

export const VisualizationPlot = ({
    borderView,
    linesToConsider,
    linesToDraw,
    polygonPoints,
    boundingLines,
    highlightDomain,
    showCriticalElements,
    showMonitoredElements,
    showOnlyLimitingElements,
    domain,
    xDisplay,
    yDisplay,
    netX,
    netY,
    loading,
    loadingMessage,
}: IVisualizationPlotProps) => {
    const dispatch = useDispatch();

    // Maybe unnecessary in the long term, usefull while in mock stage.
    const [zoom, setZoom] = useState(1);
    const [zoomLevelSlider, setZoomLevelSlider] = useState<number>(defaultZoom);

    const [hoveredLineTestId, setHoveredLineTestId] = useState<number>();
    const [hoveredLineTestFull, setHoveredLineTestFull] = useState<IFlowBasedData>();

    const [hintText, setHintText] = useState<Partial<IHintText>>();
    const [displayHint, setDisplayHint] = useState<boolean>(false);
    const [hintHideHanlder, setHintHideHandler] = useState<NodeJS.Timeout>();

    // For NetPositionData tooltip
    const [netPositionHovered, setNetPositionHovered] = useState(false);
    const [netPositionHideHandler, setNetPositionHideHandler] = useState<NodeJS.Timeout>();

    // MoreInfoDialog
    const [moreInfoOpen, setMoreInfoOpen] = useState<boolean>(false);
    const [moreInfoLoading, setMoreInfoLoading] = useState<boolean>(false);

    const windowSize: IWindowSize = useWindowSize();
    const [chartWidth, setChartWidth] = useState<number>(
        window.innerWidth > pageWidthBreakpoint ? plotWidthLarge : plotWidthSmall
    );

    // Poligon point that has been clicked. Used for "Domain vertex" display.
    const [pointHighlighted, setPointHighlighted] = useState<IPoint>();

    const [netPositionInfoOpen, setNetPositionInfoOpen] = useState(false);

    const fetchFlowbasedData = useCallback(async () => {
        setMoreInfoLoading(true);
        try {
            if (hoveredLineTestId) {
                setMoreInfoLoading(true);
                const res = await getFlowBasedData(hoveredLineTestId);
                setHoveredLineTestFull(res);
            } else {
                setHoveredLineTestFull(undefined);
            }
        } catch {
            dispatch(showToastMessage("Unable to load line info", "error"));
        } finally {
            setMoreInfoLoading(false);
        }
    }, [hoveredLineTestId, dispatch]);

    useEffect(() => {
        return () => {
            if (hintHideHanlder) clearTimeout(hintHideHanlder);
            if (netPositionHideHandler) clearTimeout(netPositionHideHandler);
        };
    });

    useEffect(() => {
        if (windowSize.width) {
            setChartWidth(windowSize.width > pageWidthBreakpoint ? plotWidthLarge : plotWidthSmall);
        }
    }, [windowSize]);

    useEffect(() => {
        fetchFlowbasedData();
    }, [fetchFlowbasedData]);

    useEffect(() => {
        if (hoveredLineTestFull) {
            let newHintText: Partial<IHintText> = {
                cneName: hoveredLineTestFull.cnE_Name,
                cneElementType: hoveredLineTestFull.cnE_ElementType,
                conName: hoveredLineTestFull.contingency_Name,
                conElementType: hoveredLineTestFull.contingency_ElementType,
                isCritical: hoveredLineTestFull.isCritical,
            };

            setHintText(newHintText);
            setDisplayHint(true);

            clearTimeout(undefined);
            let timeout = setTimeout(() => {
                setDisplayHint(false);
                setHoveredLineTestFull(undefined);
                //setHintText(undefined);
            }, displayHintMs);

            setHintHideHandler(timeout);
        } else {
            //setHintText(initHintText);
        }
    }, [hoveredLineTestFull, xDisplay, yDisplay]);

    useEffect(() => {
        if (polygonPoints.length > 0) {
            setPointHighlighted(polygonPoints[0]);
        } else {
            setPointHighlighted(undefined);
        }
    }, [polygonPoints]);

    useEffect(() => {
        setZoom(Math.pow(2, zoomLevelSlider - defaultZoom));
    }, [zoomLevelSlider, setZoom]);

    function getLineColor(isHovered: boolean, isPresolved: boolean, fallback: string) {
        if (isHovered) return "#808080";
        if (isPresolved) return "green";

        return fallback;
    }

    function getRealizedPointLabel(borderView: boolean, netPos: number, label?: string) {
        if (label === undefined) return;

        if (borderView) {
            const borderCountries = label.split("-");
            if (borderCountries.length !== 2) {
                return `${label}: ${Math.abs(netPos).toFixed(2)} MW`;
            } else {
                return `${Math.abs(netPos).toFixed(2)} MW ${
                    netPos < 0
                        ? borderCountries[1] + " to " + borderCountries[0]
                        : borderCountries[0] + " to " + borderCountries[1]
                }`;
            }
        } else {
            return `${label}: ${Math.abs(netPos).toFixed(2)} MW ${
                netPos < 0 ? "Import" : "Export"
            }`;
        }
    }

    const filterClickable = useCallback(
        (line: ILine) => {
            if (
                showCriticalElements &&
                showOnlyLimitingElements &&
                boundingLines.includes(line.info.id)
            )
                return true;
            if (showCriticalElements && !showOnlyLimitingElements && line.info.crit) return true;
            if (showMonitoredElements && !line.info.crit) return true;

            return false;
        },
        [showCriticalElements, showMonitoredElements, showOnlyLimitingElements, boundingLines]
    );

    return (
        <div className="plot-wrapper">
            <div className="positions-wrapper">
                <div
                    className={`net-position-wrapper closeable ${
                        netPositionInfoOpen ? "open" : "closed"
                    }`}
                >
                    Values represent Core internal net positions and do not include scheduled
                    exchanges on Core external borders.
                </div>
                {netX && netY && (
                    <div className="net-position-wrapper">
                        <>
                            <div className="title">
                                <span>
                                    {borderView
                                        ? "Scheduled exchanges"
                                        : "Day ahead market outcome"}
                                </span>
                                {!borderView && (
                                    <i
                                        className={`${
                                            netPositionInfoOpen ? "pi pi-times" : "pi pi-info"
                                        }`}
                                        onClick={() => setNetPositionInfoOpen(!netPositionInfoOpen)}
                                    />
                                )}
                            </div>
                            <div>{getRealizedPointLabel(borderView, netX, xDisplay)}</div>
                            <div>{getRealizedPointLabel(borderView, netY, yDisplay)}</div>
                        </>
                    </div>
                )}
                {highlightDomain && pointHighlighted && (
                    <>
                        <div className="net-position-wrapper">
                            <div>Domain vertex</div>
                            <div>
                                {getRealizedPointLabel(borderView, pointHighlighted.x, xDisplay)}
                            </div>
                            <div>
                                {getRealizedPointLabel(borderView, pointHighlighted.y, yDisplay)}
                            </div>
                        </div>
                        <div className="vertex-buttons">
                            <Button
                                label="Previous vertex"
                                className="p-button-secondary"
                                icon="pi pi-chevron-left"
                                onClick={() =>
                                    setPointHighlighted(
                                        polygonPoints[
                                            (polygonPoints.findIndex(p => p === pointHighlighted) -
                                                1 +
                                                polygonPoints.length) %
                                                polygonPoints.length
                                        ]
                                    )
                                }
                            />
                            <Button
                                label="Next vertex"
                                className="p-button-secondary"
                                icon="pi pi-chevron-right"
                                iconPos="right"
                                onClick={() =>
                                    setPointHighlighted(
                                        polygonPoints[
                                            (polygonPoints.findIndex(p => p === pointHighlighted) +
                                                1) %
                                                polygonPoints.length
                                        ]
                                    )
                                }
                            />
                        </div>
                    </>
                )}
                {!loading &&
                    !netX &&
                    !netY &&
                    linesToDraw.length === 0 &&
                    linesToConsider.length === 0 && (
                        <Message severity="warn" text="No data available for this timestamp." />
                    )}
            </div>
            <div className="plot-container">
                <Loader loading={loading} message={loadingMessage} />

                <div className="zoom-container">
                    <span>Zoom level: {zoomLevelSlider}</span>
                    <Slider
                        className="zoom-slider"
                        min={1}
                        max={10}
                        value={zoomLevelSlider}
                        onChange={e => setZoomLevelSlider(e.value as number)}
                    />
                </div>
                <XYPlot
                    className="plot"
                    width={chartWidth}
                    height={chartWidth * YRatio}
                    xDomain={[-domain / zoom, domain / zoom]}
                    yDomain={[-(domain * YRatio) / zoom, (domain * YRatio) / zoom]}
                    xType="linear"
                    yType="linear"
                    dontCheckIfEmpty
                >
                    <VerticalGridLines style={{ stroke: "#D3D4D7" }} />
                    <HorizontalGridLines style={{ stroke: "#D3D4D7" }} />

                    <XAxis
                        on0
                        style={
                            {
                                line: { stroke: "#6d6e71" },
                                ticks: { stroke: "#6d6e71" },
                                text: {
                                    stroke: "none",
                                    fill: "#6d6e71",
                                    fontWeight: 600,
                                },
                            } as any
                        }
                        title={xDisplay + (!borderView ? " net position" : "")}
                        tickTotal={10}
                    />
                    <YAxis
                        on0
                        style={
                            {
                                line: { stroke: "#6d6e71" },
                                ticks: { stroke: "#6d6e71" },
                                text: {
                                    stroke: "none",
                                    fill: "#6d6e71",
                                    fontWeight: 600,
                                },
                            } as any
                        }
                        title={yDisplay + (!borderView ? " net position" : "")}
                    />

                    {highlightDomain && polygonPoints.length > 0 && (
                        <PolygonSeries
                            className="polygon"
                            color="rgba(136, 178, 73, 0.3)"
                            data={polygonPoints}
                        />
                    )}

                    {showCriticalElements &&
                        !showOnlyLimitingElements &&
                        linesToDraw
                            .filter(l => l.info.crit)
                            .map((line: ILine) => {
                                return (
                                    <LineMarkSeries
                                        className={`line`}
                                        key={Math.random()}
                                        color={getLineColor(
                                            hoveredLineTestId === line.info.id,
                                            line.info.pre,
                                            "rgb(151,150,142)"
                                        )}
                                        strokeWidth={hoveredLineTestId === line.info.id ? 3 : 0.8}
                                        sizeBaseValue={5}
                                        size={5}
                                        data={
                                            [
                                                line.points[0],
                                                line.points[line.points.length - 1],
                                            ] as any[]
                                        }
                                    />
                                );
                            })}

                    {showMonitoredElements &&
                        linesToDraw
                            .filter(l => !l.info.crit)
                            .map((line: ILine) => {
                                return (
                                    <LineMarkSeries
                                        className={`line`}
                                        key={Math.random()}
                                        color={getLineColor(
                                            hoveredLineTestId === line.info.id,
                                            line.info.pre,
                                            "rgb(211,98,42)"
                                        )}
                                        strokeWidth={hoveredLineTestId === line.info.id ? 3 : 0.8}
                                        sizeBaseValue={5}
                                        size={5}
                                        data={
                                            [
                                                line.points[0],
                                                line.points[line.points.length - 1],
                                            ] as any[]
                                        }
                                    />
                                );
                            })}

                    {showCriticalElements &&
                        showOnlyLimitingElements &&
                        linesToConsider
                            .filter(l => boundingLines.includes(l.info.id))
                            .map((line: ILine) => {
                                return (
                                    <LineMarkSeries
                                        className="line "
                                        key={Math.random()}
                                        color={getLineColor(
                                            hoveredLineTestId === line.info.id,
                                            false,
                                            "rgb(61,122,219)"
                                        )}
                                        strokeWidth={hoveredLineTestId === line.info.id ? 3 : 1.25}
                                        sizeBaseValue={5}
                                        size={5}
                                        data={
                                            [
                                                line.points[0],
                                                line.points[line.points.length - 1],
                                            ] as any[]
                                        }
                                    />
                                );
                            })}

                    {linesToDraw.filter(filterClickable).map((line: ILine) => {
                        return (
                            <LineMarkSeries
                                className="invisible"
                                key={Math.random()}
                                color="rgba(0, 0, 0, 0)"
                                strokeWidth={5}
                                sizeBaseValue={5}
                                size={5}
                                data={
                                    [line.points[0], line.points[line.points.length - 1]] as any[]
                                }
                                onSeriesClick={() => {
                                    setHoveredLineTestId(line.info.id);
                                }}
                            />
                        );
                    })}

                    {highlightDomain && polygonPoints.length > 0 && (
                        <MarkSeries
                            sizeType="literal"
                            sizeBaseValue={5}
                            data={polygonPoints.map(pp => {
                                return { ...pp, size: 5 };
                            })}
                            strokeWidth={1}
                            color={"rgb(119, 206, 62)"}
                            onValueClick={val =>
                                setPointHighlighted({ x: val.x as number, y: val.y as number })
                            }
                        />
                    )}
                    {highlightDomain && pointHighlighted && (
                        <MarkSeries
                            sizeType="literal"
                            sizeBaseValue={5}
                            data={[{ ...pointHighlighted, size: 5 }]}
                            strokeWidth={1}
                            color="blue"
                        />
                    )}
                    {netX && netY && (
                        <MarkSeries
                            data={[{ x: netX, y: netY, size: 8 }]}
                            stroke="white"
                            fill="red"
                            sizeBaseValue={6}
                            strokeWidth={netPositionHovered ? 3 : 1}
                            onValueMouseOver={e => {
                                setNetPositionHovered(true);
                                setNetPositionHideHandler(
                                    setTimeout(() => {
                                        setNetPositionHovered(false);
                                    }, displayHintMs)
                                );
                            }}
                        />
                    )}
                    <Hint
                        style={displayHint ? displayStyle : hideStyle}
                        xType="literal"
                        yType="literal"
                        value={{ ...hintText }}
                    >
                        <i
                            className="pi pi-times close-hint-icon"
                            onClick={() => {
                                setDisplayHint(false);
                                setHoveredLineTestId(undefined);
                            }}
                        />

                        {(hintText?.cneElementType || hintText?.cneName) && (
                            <div className="title">
                                {hintText.cneElementType ?? ""} {hintText.cneName ?? ""}
                            </div>
                        )}
                        {(hintText?.conName || hintText?.conElementType) && (
                            <div>
                                <span>
                                    Contingency: {hintText.conElementType ?? ""}{" "}
                                    {hintText.conName ?? ""}
                                </span>
                            </div>
                        )}
                        <div className="title">
                            {hintText?.isCritical ? "Critical element" : "Monitored element"}
                        </div>
                        <div className="more-info-container">
                            <Button
                                className="more-info p-button-sm"
                                icon="pi pi-info-circle"
                                label="More info"
                                onClick={() => setMoreInfoOpen(true)}
                            />
                        </div>
                    </Hint>
                </XYPlot>
                <Message
                    className="info-message"
                    severity="info"
                    text="Chart represents final flow based domain after long term nomination (LTN) inclusion."
                />
                <div className="data-source">
                    Data source:{" "}
                    <a
                        href="https://publicationtool.jao.eu/core/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        JAO Publication Tool
                    </a>
                </div>
            </div>
            <MoreInfoDialog
                visible={moreInfoOpen}
                onHide={() => setMoreInfoOpen(false)}
                loading={moreInfoLoading}
                data={hoveredLineTestFull}
            />
        </div>
    );
};
