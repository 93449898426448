import { ProgressSpinner } from "primereact/progressspinner";
import "./Loader.css";

export interface ILoaderProps {
    loading: boolean;
    message?: string;
}

export const Loader = ({ loading, message }: ILoaderProps) => {
    return (
        <div className={`loader ${loading ? "active" : ""}`}>
            <ProgressSpinner />
            <p>{message}</p>
        </div>
    );
};
