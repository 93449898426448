import axios from "axios";
import { ILinearParametersWithNetPosition } from "../models/linearEquationParameters";

export const getBorderExchangesParameters = async (
    dateTime: string,
    border1?: string,
    border2?: string
) => {
    const res = await axios.get("/BorderExchangeParameters/forDay", {
        params: { dateTime, border1, border2 },
    });
    return res.data as ILinearParametersWithNetPosition;
};
