import { Dispatch, SetStateAction } from "react";
import "./HourPicker.css";

export interface IHourPickerProps {
    selectedHour: number;
    setSelectedHour: Dispatch<SetStateAction<number>>;
}

export const HourPicker = ({ selectedHour, setSelectedHour }: IHourPickerProps) => {
    return (
        <div className="hour-picker">
            {[...Array(24)].map((_, index) => {
                return (
                    <div
                        className={`hour-tab ${index === selectedHour ? "selected" : ""}`}
                        key={index}
                        onClick={e => setSelectedHour(index)}
                    >
                        <span>{index.toString().padStart(2, "0")}</span>
                        <span>-</span>
                        <span>{(index + 1).toString().padStart(2, "0")}</span>
                    </div>
                );
            })}
        </div>
    );
};
