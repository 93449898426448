import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserData, UserRole } from "../../actions/authentificationActions";
import { editUser, getUsers } from "../../api/users";
import { showToastMessage } from "../../actions/toastMessageActions";
import { getDisplayDateFormat, getISODateFormat } from "../../helpers/DateHelper";
import { AppState } from "../../store/configureStore";
import { UserHasRole } from "../../helpers/RolesHelper";
import { Unauthorized } from "../Unauthorized/Unauthorized";
import { Checkbox } from "primereact/checkbox";
import { Calendar } from "primereact/calendar";
import "./UsersDashboard.css";

export const UsersDashboard = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user);
    const [users, setUsers] = useState<UserData[]>([]);

    const fetchUsers = useCallback(async () => {
        try {
            const res = await getUsers();
            setUsers(res);
        } catch (err) {
            dispatch(showToastMessage("Unable to load users", "error"));
        }
    }, [dispatch]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const onUserUpdate = async (user: UserData) => {
        try {
            await editUser(user);
            dispatch(showToastMessage("User successfully updated", "success"));
            await fetchUsers();
        } catch (err) {
            dispatch(showToastMessage("Unable to update user", "error"));
        }
    };

    return (
        <div className="users-dashboard-container">
            {!UserHasRole(user, UserRole.Admin) && <Unauthorized />}
            <h1>Manage Users</h1>
            <table className="users-dashboard-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Times logged in</th>
                        <th>Last login time</th>
                        <th>Active</th>
                        <th>Acount valid until</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(u => {
                        return (
                            <tr key={u.guid}>
                                <td>{`${u.firstName} ${u.lastName}`}</td>
                                <td>{u.userName}</td>
                                <td>{u.timesLoggedIn}</td>
                                <td>{getDisplayDateFormat(u.lastLoginDate, true)}</td>
                                <td className="centered">
                                    <Checkbox
                                        disabled={u.guid === user.guid}
                                        checked={u.isActive}
                                        onChange={e => onUserUpdate({ ...u, isActive: e.checked })}
                                    />
                                </td>
                                <td>
                                    <Calendar
                                        disabled={u.guid === user.guid}
                                        value={new Date(u.accountValidUntil ?? "")}
                                        onChange={e => {
                                            if (e.value) {
                                                onUserUpdate({
                                                    ...u,
                                                    isActive: true,
                                                    accountValidUntil:
                                                        getISODateFormat(
                                                            Array.isArray(e.value)
                                                                ? e.value[0]
                                                                : e.value
                                                        ) ?? "",
                                                });
                                            }
                                        }}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
