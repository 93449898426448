import { ILine } from "../models/plotModels";

export const filterLineByTso = (line: ILine, countryShortcuts: string[]): boolean => {
    let t = countryShortcuts.includes(tsoToCountry(line.info.cne));

    return t;
};

function tsoToCountry(tso?: string): string {
    if (!tso) return "";

    switch (tso.toUpperCase()) {
        case "APG":
            return "at";
        case "ELIA":
            return "be";
        case "CEPS":
            return "cz";
        case "HOPS":
            return "hr";
        case "ELES":
            return "si";
        case "PSE":
            return "pl";
        case "SEPS":
            return "sk";
        case "RTE":
            return "fr";
        case "TRANSELECTRICA":
            return "ro";
        case "TENNETBV":
            return "nl";
        case "MAVIR":
            return "hu";

        case "TRANSNETBW":
        case "TENNETGMBH":
        case "AMPRION":
        case "50HERTZ":
            return "de";
        default:
            return "Unknown TSO";
    }
}
